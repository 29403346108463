``
<template>
  <div>
    <div class="bottom_menu_cont">
      <div class="bottom_menu">
        <button
          class="bottom_menu_btn"
          @click="add_contact"
          :disabled="!active"
        >
          <img src="../assets/bottom_menu/new_card_btn.png" alt="" />
          <div class="menu_line" v-if="bottom_menu_page == 1"></div>
        </button>
        <button
          class="bottom_menu_btn"
          @click="list_contacts"
          :disabled="update"
        >
          <img src="../assets/bottom_menu/list_cards_btn.png" alt="" />
          <div class="menu_line" v-if="bottom_menu_page == 2"></div>
        </button>
        <button class="bottom_menu_btn" @click="view_profile">
          <img src="../assets/bottom_menu/user_acc_btn.png" alt="" />
          <div class="menu_line" v-if="bottom_menu_page == 3"></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomMenu",
  props: {
    bottom_menu_page: Number,
  },
  computed: {
    active() {
      return this.$store.getters.GET_CURRENT_EVENT.active;
    },
  },
  data() {
    return {
      update: false,
    };
  },
  methods: {
    add_contact() {
      let vm = this;
      this.$store
        .dispatch("SET_LOAD_STATUS", true)
        .then(vm.$router.push("/add_contact"));
    },
    list_contacts() {
      let vm = this;
      if (this.bottom_menu_page == 2) {
        this.update = true;
        this.$store.dispatch("SET_CONTACTS").then((vm.update = false));
        return;
      }
      this.$store
        .dispatch("SET_LOAD_STATUS", true)
        .then(vm.$router.push("/list_contacts"));
    },
    view_profile() {
      let vm = this;
      if (this.bottom_menu_page == 3) {
        return;
      }
      this.$store
        .dispatch("SET_LOAD_STATUS", true)
        .then(vm.$router.push("/view_profile"));
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom_menu_cont {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #292a4d;
  opacity: 0.95;
  height: 9%;
  box-shadow: 0 0 20px 0 #000;
}
.bottom_menu {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 40px 20px 40px;
}
.bottom_menu_btn {
  background: none;
  border: none;
}
.bottom_menu_btn:active {
  opacity: 0.7;
}
.bottom_menu_btn:disabled {
  filter: grayscale(1);
}
.menu_line {
  width: 100%;
  height: 3px;
  background-color: #aa94da;
  margin-top: 3px;
}
</style>
