<template>
  <div>
    <div class="load_container container" v-if="load_scene == `default`">
      <img src="../assets/logo.png" alt="" />
    </div>
    <div class="save_container container" v-if="load_scene == `save`">
      <img src="../assets/logo.png" alt="" />
      <img src="../assets/loading.gif" alt="" class="load_gif" />
      <p class="save_text">Старательно сохраняем контакт</p>
    </div>
    <div class="hello_container container" v-if="load_scene == `hello`">
      <transition appear name="logo">
        <img src="../assets/logo.png" alt="" />
      </transition>
      <transition appear name="full_name">
        <h1 class="full_name">{{ full_name }},</h1>
      </transition>
      <transition appear name="u_awesome">
        <h1 class="u_awesome">
          Ты создаёшь<br />
          этот мир!
        </h1>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderWin",
  data() {
    return {
      full_name: localStorage.getItem("full_name"),
    };
  },
  computed: {
    load_scene() {
      return this.$store.getters.GET_LOAD_VIEW;
      // return "save";
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-enter-active,
.logo-leave-active {
  transition: opacity 1s ease 0.1s;
}

.logo-enter-from,
.logo-leave-to {
  opacity: 0;
}
.full_name-enter-active,
.full_name-leave-active {
  transition: opacity 1s ease 0.6s;
}

.full_name-enter-from,
.full_name-leave-to {
  opacity: 0;
}
.u_awesome-enter-active,
.u_awesome-leave-active {
  transition: opacity 1s ease 1.1s;
}

.u_awesome-enter-from,
.u_awesome-leave-to {
  opacity: 0;
}

.container {
  position: fixed;
  z-index: 300;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-attachment: fixed;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: 25% 25%;
  font-family: "Zen Kaku Gothic New", sans-serif;
}
.load_container {
  position: fixed;
  z-index: 300;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: 25% 25%;
  background: radial-gradient(
    circle at 50% 50%,
    #b3c5ff 1%,
    #4860c0 40%,
    #161629
  );
}
.hello_container {
  background: radial-gradient(
    circle at 50% 40%,
    #b3c5ff 1%,
    #4860c0 40%,
    #161629
  );
}
.save_container {
  background: radial-gradient(
    circle at 50% 40%,
    #b3c5ff 1%,
    #4860c0 40%,
    #161629
  );
}
.hello_container {
  color: white;
  // margin-bottom: 30px;
  // text-shadow: 2px 2px black;
}
@keyframes example {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.load_gif {
  width: 15%;
}
.save_text {
  color: white;
  // text-shadow: 2px 2px black;
  font-size: 2rem;
  margin-top: 30px;
}
.full_name {
  margin-top: 2%;
}
.u_awesome {
  margin-top: 4%;
  font-weight: normal;
}
</style>
