<template>
  <div class="list_card_comp">
    <div class="navbarContainer">
      <!-- <div class="navigation">
        <div class="navigation_back">
          <button class="navigation_back_btn" @click="logoff">
            <img src="../assets/back_btn_white.png" alt="" />Выход
          </button>
        </div>
      </div> -->
      <div class="current_vistavka">
        <h4>Выберите выставку</h4>
      </div>
    </div>
    <div class="list_cards">
      <button
        class="item_events"
        :class="current_event.id == event.id ? `selected` : `not_selected`"
        v-for="event in events"
        :key="event.id"
        @click="choose_event(event.id)"
      >
        <div class="event_image">
          <img src="../assets/event_preview.png" alt="" />
        </div>
        <div class="descr">
          <div>
            <h2>{{ event.name }}</h2>
            <p>
              {{ ("0" + event.start_date.getDate()).slice(-2) }}.{{
                ("0" + (event.start_date.getMonth() + 1)).slice(-2)
              }}.{{ event.start_date.getFullYear() }} -
              {{ ("0" + event.end_date.getDate()).slice(-2) }}.{{
                ("0" + (event.end_date.getMonth() + 1)).slice(-2)
              }}.{{ event.end_date.getFullYear() }}
            </p>
          </div>
          <div class="read_only">
            <img src="../assets/eye.png" alt="" v-if="!event.active" />
          </div>
        </div>
      </button>
      <!-- <div class="asdf" v-if="events.length > 2"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ListEvents",
  components: {},
  computed: {
    events() {
      return this.$store.getters.GET_EVENTS;
    },
    current_event() {
      return this.$store.getters.GET_CURRENT_EVENT;
    },
  },
  beforeRouteEnter(to, from, next) {
    setTimeout(() => next(), 100);
  },
  beforeCreate() {
    this.$store.dispatch("SET_EVENTS");
  },
  mounted() {
    let vm = this;
    if (this.$store.getters.GET_LOAD_VIEW == "hello") {
      setTimeout(
        () =>
          vm.$store
            .dispatch("SET_LOAD_STATUS", false)
            .then(vm.$store.dispatch("SET_LOAD_VIEW", "default")),
        2000
      );
    } else {
      setTimeout(() => vm.$store.dispatch("SET_LOAD_STATUS", false), 100);
    }
  },
  methods: {
    choose_event(event_id) {
      this.$store.dispatch("SET_LOAD_STATUS", true);
      this.$store
        .dispatch("SET_CURRENT_EVENT", event_id)
        .then(this.$router.push("/list_contacts"));
    },
    logoff() {
      this.$store.dispatch("LOGOFF").then(this.$router.push("/login"));
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.list_card_comp {
  background: #292a4d;
  background: linear-gradient(0deg, #292a4d 0, #6c70f0 65%, #292a4d 100%);
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  height: 100svh;
}
.navbarContainer {
  color: white;
  width: 100%;
  padding: 30px 0;
}
.navigation {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.asdf {
  height: 11%;
  margin-top: 10px;
  width: 100%;
}
.navigation_back_btn {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  width: 110%;
  justify-content: space-between;
}
.navigation_back_btn:active {
  opacity: 0.7;
}
.current_vistavka {
  margin: 30px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
}
.list_cards {
  width: 100%;
  overflow: auto;
  padding: 0 5%;
}
.item_events {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: none;
  margin-top: 10px;
}
.not_selected {
  border: none;
}
.selected {
  border: white 3px solid;
}
.item_events:active {
  opacity: 0.7;
}
.event_image {
  width: 100%;
  min-height: 150px;
  background-color: grey;
}
.event_image img {
  width: 100%;
  vertical-align: bottom;
}
.descr {
  background-color: #292a4d;
  color: white;
  text-align: left;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.read_only {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  height: 100%;
  opacity: 0.5;
}
.read_only img {
  height: 70%;
}
</style>
