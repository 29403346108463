import { createRouter, createWebHistory } from "vue-router";
import ListEvents from "../components/ListEvents.vue";
import LoginWin from "../components/LoginWin.vue";
import ListContacts from "../components/ListContacts.vue";
import AddContact from "../components/AddContact.vue";
import ViewProfile from "../components/ViewProfile.vue";
import ViewContact from "../components/ViewContact.vue";
import EditField from "../components/EditField.vue";
import App from "../App.vue";
import axios from "axios";
import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "App",
    component: App,
    beforeEnter: async () => {
      console.log("router");
      let token = localStorage.getItem("token");
      let return_path = "";
      if (token) {
        await axios
          .get(`${process.env.VUE_APP_backurl}/check_token`, {
            headers: {
              token: token,
            },
          })
          .then((Responce) => {
            if (Responce.data == "Ok") {
              if (localStorage.getItem("current_event")) {
                return_path = "list_contacts";
              } else {
                return_path = "list_events";
              }
            } else {
              store
                .dispatch("SET_LOAD_VIEW", "default")
                .then((return_path = "login"));
            }
          });
      } else {
        store
          .dispatch("SET_LOAD_VIEW", "default")
          .then((return_path = "login"));
      }
      return { name: return_path };
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginWin,
    beforeEnter: async () => {
      store.dispatch("SET_LOAD_VIEW", "default");
    },
  },
  {
    path: "/list_events",
    name: "list_events",
    component: ListEvents,
  },
  {
    path: "/edit_field",
    name: "edit_field",
    component: EditField,
  },
  {
    path: "/list_contacts",
    name: "list_contacts",
    component: ListContacts,
  },
  {
    path: "/add_contact",
    name: "add_contact",
    component: AddContact,
    beforeEnter: (to, from) => {
      if (from.name == "list_contacts" || from.name == "view_profile") {
        return true;
      } else {
        return { name: "App" };
      }
    },
  },
  {
    path: "/view_profile",
    name: "view_profile",
    component: ViewProfile,
  },
  {
    path: "/view_contact",
    name: "view_contact",
    component: ViewContact,
    beforeEnter: (to, from) => {
      if (from.name == "list_contacts") {
        return true;
      } else {
        return { name: "App" };
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  // console.log("to " + to.name);
  // console.log("from " + from.name);
  if (localStorage.getItem("current_event") || localStorage.getItem("token")) {
    return true;
  } else if (to.name == "login") {
    return true;
  } else {
    return { name: "login" };
  }
});

export default router;
