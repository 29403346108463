<template>
  <div>
    <router-view />
    <PingWarn v-if="!online_status" />
    <!-- <PingWarn /> -->
    <transition name="loader">
      <LoaderWin v-if="load_status" />
      <!-- <LoaderWin /> -->
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import PingWarn from "./components/PingWarn.vue";
import LoaderWin from "./components/LoaderWin.vue";

export default {
  name: "App",
  components: {
    PingWarn,
    LoaderWin,
  },
  data() {
    return {
      online_status: true,
    };
  },
  computed: {
    state_token() {
      return this.$store.getters.GET_USER.token;
    },
    load_status() {
      return this.$store.getters.GET_LOAD_STATUS;
    },
  },
  beforeCreate() {
    // this.$store.dispatch("SET_LOAD_STATUS", true);
    if (localStorage.getItem("token")) {
      this.$store.dispatch("SET_USER_INFO", localStorage.getItem("token"));
    }
    console.log("get user info");
  },
  mounted() {
    console.log("App");
    setInterval(
      function () {
        axios
          .get(`${process.env.VUE_APP_backurl}/ping`)
          .then((Responce) => {
            if (Responce.data == "Yes") {
              this.online_status = true;
            }
          })
          .catch(() => {
            this.online_status = false;
          });
      }.bind(this),
      3000
    );
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+Antique:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@400;700&display=swap");
#app {
  font-family: "Zen Kaku Gothic Antique", sans-serif;
  text-align: center;
  background-color: #eeeeee;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.loader-enter-active,
.loader-leave-active {
  transition: opacity 0.1s ease;
}

.loader-enter-from,
.loader-leave-to {
  opacity: 0;
}
</style>
