<template>
  <div class="list_card_comp">
    <div class="navbarContainer">
      <div class="navigation">
        <transition name="search_input">
          <input
            ref="search_inp_el"
            type="text"
            v-if="show_search"
            v-model="search_text"
            class="search_box"
          />
        </transition>
        <button class="search_btn" @click="click_search" v-if="!this.search">
          <img src="../assets/search_btn.png" alt="" />
        </button>
        <button
          class="close_btn"
          v-if="this.search"
          @click="this.search_text = ``"
        >
          &times;
        </button>
      </div>
      <div class="current_vistavka">
        <h4>{{ current_event.name }}</h4>
        <p>{{ contact_values.length }} к.</p>
        <select v-model="day">
          <option value="all">Все</option>
          <option
            v-for="date in date_filter_values"
            :key="date.id"
            :value="date"
          >
            {{ date }}
          </option>
        </select>
      </div>
    </div>
    <div class="list_cards">
      <div
        class="card_item"
        v-for="contact in contact_values"
        :key="contact.id"
      >
        <div class="company_contact">
          <p class="company quick_inf_type">Компания</p>
          <h3 class="company_name">{{ contact.public_name }}</h3>
        </div>
        <div class="fio_contact">
          <p class="fio quick_inf_type">ФИО</p>
          <h3 class="fio_name">{{ contact.contact_full_name }}</h3>
        </div>
        <button class="go_to_card_btn">
          <img
            src="../assets/GoToCardBtn.png"
            alt=""
            @click="choose_contact(contact.id)"
          />
        </button>
      </div>
      <div class="asdf" v-if="contacts.length > 8"></div>
    </div>
    <!-- <div class="asdf"></div> -->
    <BottomMenu :bottom_menu_page="2" />
  </div>
</template>

<script>
import BottomMenu from "./BottomMenu.vue";

export default {
  name: "ListContacts",
  data() {
    return {
      search: false,
      search_text: "",
      date_filter_value: "all",
      contact_values: [],
    };
  },
  components: {
    BottomMenu,
  },
  beforeRouteEnter(to, from, next) {
    setTimeout(() => next(), 100);
  },
  beforeCreate() {
    let vm = this;
    this.$store
      .dispatch("SET_CURRENT_EVENT_FROM_LOCALSTORAGE")
      .then(vm.$store.dispatch("SET_CONTACTS"));
  },
  mounted() {
    let vm = this;
    console.log("ListContacts");

    if (this.$store.getters.GET_LOAD_VIEW == "hello") {
      setTimeout(
        () =>
          vm.$store
            .dispatch("SET_LOAD_STATUS", false)
            .then(vm.$store.dispatch("SET_LOAD_VIEW", "default")),
        2000
      );
    } else if (this.$store.getters.GET_LOAD_VIEW == "save") {
      setTimeout(
        () =>
          vm.$store
            .dispatch("SET_LOAD_STATUS", false)
            .then(vm.$store.dispatch("SET_LOAD_VIEW", "default")),
        100
      );
    } else {
      setTimeout(() => vm.$store.dispatch("SET_LOAD_STATUS", false), 100);
    }
  },
  computed: {
    current_event() {
      return this.$store.getters.GET_CURRENT_EVENT;
    },
    date_filter_values() {
      return this.$store.getters.GET_DATE_FILTER_VALUES;
    },
    contact_vals() {
      return this.contact_values;
    },
    contacts() {
      return this.$store.getters.GET_CONTACTS;
    },
    show_search() {
      return this.search;
    },
    day: {
      get() {
        return this.date_filter_value;
      },
      set(value) {
        console.log("CHANGE");
        if (value == "all") {
          this.contact_values = this.contacts;
        } else {
          this.contact_values = this.contacts.filter((contact) => {
            return contact.event_date.includes(value);
          });
        }
        this.date_filter_value = value;
      },
    },
  },
  watch: {
    contacts(newVal) {
      this.contact_values = newVal;
    },
    search_text(newVal) {
      this.contact_values = this.contacts.filter((contact) => {
        if (this.day == "all") {
          return (
            contact.public_name.toLowerCase().includes(newVal.toLowerCase()) ||
            contact.contact_full_name
              .toLowerCase()
              .includes(newVal.toLowerCase())
          );
        } else {
          return (
            contact.event_date.includes(this.day) &&
            (contact.public_name.toLowerCase().includes(newVal.toLowerCase()) ||
              contact.contact_full_name
                .toLowerCase()
                .includes(newVal.toLowerCase()))
          );
        }
      });
    },
  },
  methods: {
    choose_contact(contact_id) {
      let vm = this;
      this.$store.dispatch("SET_LOAD_STATUS", true);
      this.$store
        .dispatch("SET_CURRENT_CONTACT", contact_id)
        .then(vm.$router.push(`/view_contact`));
    },
    click_search() {
      this.search = !this.search;
      this.$nextTick(() => this.$refs.search_inp_el.focus());
    },
  },
};
</script>

<style lang="scss" scoped>
.back_btn_text-enter-active,
.back_btn_text-leave-active {
  transition: opacity 0.1s ease;
}

.back_btn_text-enter-from,
.back_btn_text-leave-to {
  opacity: 0;
}
.search_input-enter-active,
.search_input-leave-active {
  transition: opacity 0.1s ease;
  transition-delay: 0.3s;
}

.search_input-enter-from,
.search_input-leave-to {
  opacity: 0;
}
.list_card_comp {
  height: 100svh;
  background: #292a4d;
  background: linear-gradient(0deg, #292a4d 0, #6c70f0 65%, #292a4d 100%);
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
.navbarContainer {
  color: white;
  width: 100%;
  padding: 30px 0;
}
.navigation {
  margin: 0 20px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.asdf {
  height: 12%;
  margin-top: 10px;
  width: 100%;
}
.navigation_back_btn {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navigation_back_btn p {
  margin-left: 10px;
}
.navigation_back_btn:active {
  opacity: 0.7;
}
.search_box {
  width: 90%;
  color: white;
  border: none;
  border-bottom: 2px white solid;
  background: none;
  line-height: 25px;
  font-size: 1.5rem;
}
.search_btn,
.close_btn {
  background: none;
  border: none;
}
.close_btn {
  font-size: 2.5rem;
  color: white;
  line-height: 25px;
}
.current_vistavka {
  margin: 30px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
}
.list_cards {
  padding: 0 20px;
  overflow: auto;
}
.card_item {
  background-color: white;
  display: flex;
  border-radius: 20px;
  padding: 5px 15px;
  justify-content: space-between;
  margin-top: 10px;
}
.company_contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 35%;
}
.quick_inf_type {
  color: grey;
}
.company_name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.fio_contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 50%;
}
.fio {
  color: grey;
}
.fio_name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.go_to_card_btn {
  border: none;
  background: none;
}
.go_to_card_btn:active {
  opacity: 0.7;
}
</style>
