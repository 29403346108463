import { createStore } from "vuex";
import axios from "axios";
import router from ".././router";

const default_store = {
  load: {
    status: true,
    view: "hello",
  },
  user: {},
  events: {
    current_event: {},
    list_events: [],
  },
  contacts: {
    current_contact: {},
    list_contacts: [],
  },
};

const state = {
  load: {
    status: true,
    view: "hello",
  },
  user: {},
  events: {
    current_event: {},
    list_events: [],
  },
  contacts: {
    current_contact: {},
    list_contacts: [],
    date_filter_values: [],
  },
  add_contact: {
    activity_profile: [
      "Розница",
      "ОПТ",
      "Сеть",
      "Интернет",
      "Маркетплейс",
      "Прочее",
    ],
    industry: [
      "Детские товары",
      "Канцелярия",
      "Книжный",
      "Продовольствия",
      "Drogeire",
      "Прочее",
    ],
    liked_categories: [
      "Все товары",
      "Косметика",
      "Куклы",
      "Украшения",
      "Слаймы",
      "Песок",
      "Опыты",
      "Легкий пластилин",
      "Тесто",
    ],
  },
};

const getters = {
  GET_EVENTS: (state) => {
    return state.events.list_events;
  },
  GET_CURRENT_EVENT: (state) => {
    return state.events.current_event;
  },
  GET_CONTACTS: (state) => {
    return state.contacts.list_contacts;
  },
  GET_DATE_FILTER_VALUES: (state) => {
    return state.contacts.date_filter_values;
  },
  GET_CURRENT_CONTACT: (state) => {
    return state.contacts.current_contact;
  },
  GET_USER: (state) => {
    return state.user;
  },
  GET_LOAD_STATUS: (state) => {
    return state.load.status;
  },
  GET_LOAD_VIEW: (state) => {
    return state.load.view;
  },
  GET_CHECKBOX_VALUES: (state) => (type) => {
    return state.add_contact[type];
  },
};

const mutations = {
  SET_USER_INFO(state, payload) {
    state.user = payload;
  },
  SET_EVENTS(state, payload) {
    state.events.list_events = payload;
  },
  SET_CONTACTS(state, payload) {
    state.contacts.list_contacts = payload;
  },
  SET_DATE_FILTER_VALUES(state, payload) {
    state.contacts.date_filter_values = payload;
  },
  SET_CURRENT_CONTACT(state, payload) {
    state.contacts.current_contact = payload;
  },
  SET_CURRENT_EVENT(state, payload) {
    state.events.current_event.id = payload.id;
    state.events.current_event.name = payload.name;
    state.events.current_event.current_day = payload.current_day;
    state.events.current_event.days = payload.days;
    state.events.current_event.active = payload.active;
  },
  SET_CURRENT_EVENT_DAY(state, payload) {
    state.events.current_event.current_day = payload;
  },
  LOGOFF(state) {
    Object.assign(state, default_store);
  },
  SET_LOAD_STATUS(state, payload) {
    state.load.status = payload;
  },
  SET_LOAD_VIEW(state, payload) {
    state.load.view = payload;
  },
};

const actions = {
  SUCCESS_LOGON: async (context, payload) => {
    context.commit("SET_USER_INFO", payload);
    localStorage.setItem("token", payload.token);
    localStorage.setItem("full_name", payload.full_name);
    localStorage.setItem("user_id", payload.id);
  },
  SET_USER_INFO: async (context, payload) => {
    await axios
      .get(`${process.env.VUE_APP_backurl}/get_user_info`, {
        headers: {
          token: payload,
        },
      })
      .then(function (Responce) {
        if (Responce.data == "User isn't exist") {
          router.push("/login");
        } else {
          context.commit("SET_USER_INFO", Responce.data);
          localStorage.setItem("full_name", Responce.data.full_name);
          localStorage.setItem("user_id", Responce.data.id);
        }
      });
    console.log("Записана информация о пользователе");
    return;
  },
  SET_EVENTS: async (context) => {
    await axios
      .get(`${process.env.VUE_APP_backurl}/get_events`, {
        headers: {
          token: localStorage.getItem("token"),
        },
      })
      .then(function (Responce) {
        if (Responce.data == "Unathorized") {
          router.push("/login");
          console.log("return to login");
          return;
        }
        for (let item in Responce.data) {
          Responce.data[item].start_date = new Date(
            Responce.data[item].start_date
          );
          Responce.data[item].end_date = new Date(Responce.data[item].end_date);
        }
        context.commit("SET_EVENTS", Responce.data);
      });
    return;
  },
  SET_CONTACTS: async (context) => {
    let responce_data = "";
    // let day_val = new Date(
    //   JSON.parse(localStorage.getItem("current_event")).current_day
    // );
    // let day = `${day_val.getFullYear()}-${
    //   day_val.getMonth() + 1
    // }-${day_val.getDate()}`;
    // console.log(day);
    // console.log(JSON.parse(localStorage.getItem("current_event")));
    await axios
      .get(
        `${process.env.VUE_APP_backurl}/get_quickinf_contacts/${
          JSON.parse(localStorage.getItem("current_event")).id
        }`,
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      )
      .then(function (Responce) {
        responce_data = Responce.data;
      });
    if (responce_data == "Unathorized") {
      context.dispatch(`LOGOFF`);
      console.log("return to login");
      return;
    }
    context.commit("SET_CONTACTS", responce_data);
    await axios
      .get(
        `${process.env.VUE_APP_backurl}/get_date_filter/${
          JSON.parse(localStorage.getItem("current_event")).id
        }`
      )
      .then(function (Responce) {
        context.commit("SET_DATE_FILTER_VALUES", Responce.data);
      });
  },
  SET_CURRENT_CONTACT: async (context, payload) => {
    await axios
      .get(`${process.env.VUE_APP_backurl}/get_contact/${payload}`)
      .then(function (Responce) {
        Responce.data.created_at = new Date(Responce.data.created_at);
        context.commit("SET_CURRENT_CONTACT", Responce.data);
      });
  },
  SET_CURRENT_EVENT: async (context, payload) => {
    let event_list = state.events.list_events;
    let days = [];
    for (let item in event_list) {
      if (event_list[item].id == payload) {
        let start_date = new Date(event_list[item].start_date);
        let end_date = new Date(event_list[item].end_date);
        while (start_date <= end_date) {
          days.push(new Date(start_date));
          start_date.setDate(start_date.getDate() + 1);
        }
        let current_event = {
          id: event_list[item].id,
          name: event_list[item].name,
          current_day: new Date(event_list[item].start_date),
          days: days,
          active: event_list[item].active,
        };
        context.commit("SET_CURRENT_EVENT", current_event);
        localStorage.setItem("current_event", JSON.stringify(current_event));

        break;
      }
    }
  },
  SET_CURRENT_EVENT_FROM_LOCALSTORAGE: async (context) => {
    let event = JSON.parse(localStorage.getItem("current_event"));
    event.current_day = new Date(event.current_day);
    for (let item in event.days) {
      event.days[item] = new Date(event.days[item]);
    }
    context.commit("SET_CURRENT_EVENT", event);
    return;
  },
  SET_CURRENT_EVENT_DAY: async (context, payload) => {
    let event = JSON.parse(localStorage.getItem("current_event"));
    event.current_day = new Date(payload);
    localStorage.setItem("current_event", JSON.stringify(event));
    context.commit("SET_CURRENT_EVENT_DAY", new Date(payload));
    return;
  },
  LOGOFF: async (context) => {
    localStorage.clear();
    context.commit("LOGOFF");
    router.push("/login");
    return;
  },
  SET_LOAD_STATUS: async (context, payload) => {
    context.commit("SET_LOAD_STATUS", payload);
    return;
  },
  SET_LOAD_VIEW: async (context, payload) => {
    context.commit("SET_LOAD_VIEW", payload);
    return;
  },
};
const modules = {};

const store = createStore({
  strict: true,
  state,
  getters,
  mutations,
  actions,
  modules,
});

export default store;
