<template>
  <div>
    <div class="navbarContainer">
      <div class="navcont">
        <h3>
          {{ current_event.name }} {{ ("0" + today.getDate()).slice(-2) }}.{{
            ("0" + (today.getMonth() + 1)).slice(-2)
          }}.{{ today.getFullYear() }}
        </h3>
        <button
          class="close_btn"
          @click="
            $store.dispatch(`SET_LOAD_STATUS`, true);
            $router.push(`/list_contacts`);
          "
        >
          &times;
        </button>
      </div>
    </div>
    <form @submit.prevent="submit_form" class="login_form">
      <div class="card">
        <div class="general_inf">
          <label class="linp"
            >Публичное наименование*<input
              type="text"
              class="text_inp"
              :placeholder="placeholder_text"
              name="public_name"
              required
          /></label>
          <label class="linp"
            >Юридическое лицо<input
              type="text"
              class="text_inp"
              :placeholder="placeholder_text"
              name="legal_name"
          /></label>
          <label class="linp"
            >Город, регион*<input
              list="cities"
              type="text"
              class="text_inp"
              :placeholder="placeholder_text"
              name="location"
              @input="request_list"
              required
          /></label>
          <datalist id="cities">
            <option v-for="city in cities" :key="city.id">{{ city }}</option>
          </datalist>
          <div class="profile">
            <label class="linp">Профиль деятельности*</label>
            <div class="inputs">
              <div
                class="col"
                v-for="column in columns(checkboxed_values(`activity_profile`))"
                :key="column.id"
              >
                <label
                  class="checkb"
                  v-for="profile in column"
                  :key="profile.id"
                  ><input
                    type="checkbox"
                    class="check_btn"
                    v-model="activity_profile"
                    :value="profile"
                  />
                  <div class="check_icon"></div>
                  <span class="checkmark">{{ profile }}</span></label
                >
              </div>
            </div>
          </div>
          <div class="otrasl">
            <label class="linp">Отрасль</label>
            <div class="inputs">
              <div
                class="col"
                v-for="column in columns(checkboxed_values(`industry`))"
                :key="column.id"
              >
                <label
                  class="checkb"
                  v-for="industry_item in column"
                  :key="industry_item.id"
                  ><input
                    type="checkbox"
                    class="check_btn"
                    v-model="industry"
                    :value="industry_item"
                  />
                  <div class="check_icon"></div>
                  <span class="checkmark">{{ industry_item }}</span></label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="contact_inf">
          <label class="linp"
            >ФИО*<input
              type="text"
              class="text_inp"
              :placeholder="placeholder_text"
              name="contact_full_name"
              required
          /></label>
          <label class="linp"
            >Должность<input
              type="text"
              class="text_inp"
              :placeholder="placeholder_text"
              name="contact_job_title"
          /></label>
          <label class="linp"
            >Телефон*<input
              type="tel"
              class="text_inp"
              :placeholder="placeholder_text"
              name="phone_number1"
              inputmode="tel"
              v-model="phone_number[0]"
              required /><input
              type="tel"
              class="s_text_inp"
              :placeholder="placeholder_text"
              name="phone_number2"
              inputmode="tel"
              v-model="phone_number[1]"
              :disabled="!phone_number[0] ? true : false"
          /></label>
          <label class="linp"
            >E-mail*
            <div class="email_input text_inp">
              <input
                type="email"
                :placeholder="placeholder_text"
                name="email"
                inputmode="email"
                @input="email_check"
                required
              /><img :src="image_url" alt="" class="check_email_icon" />
            </div>
          </label>
        </div>
        <div class="line"></div>
        <div class="liked_inf">
          <div class="profile">
            <label class="linp">Интересные категории</label>
            <div class="inputs">
              <div
                class="col"
                v-for="column in columns(checkboxed_values(`liked_categories`))"
                :key="column.id"
              >
                <label
                  class="checkb"
                  v-for="category in column"
                  :key="category.id"
                  ><input
                    type="checkbox"
                    class="check_btn"
                    v-model="liked_categories"
                    :value="category"
                  />
                  <div class="check_icon"></div>
                  <span class="checkmark">{{ category }}</span></label
                >
              </div>
            </div>
          </div>
          <label class="linp"
            >Комментарий<input
              type="textarea"
              class="text_inp"
              :placeholder="placeholder_text"
              name="comment"
          /></label>
          <div class="robot">
            <label class="linp">Подтвердите, что вы не робот :)</label>
            <div class="robots">
              <label
                class="radiob"
                v-for="robot in [1, 2, 3, 4, 5]"
                :key="robot.id"
                ><input
                  type="radio"
                  class="radio_btn"
                  v-model="is_robot"
                  :value="robot"
                /><span class="radiomark">{{ robot }}</span></label
              >
            </div>
          </div>
        </div>
        <div class="photo_visitka">
          <div class="photos">
            <div class="photo">
              <img :src="url1" alt="" />
            </div>
            <div class="photo"><img :src="url2" alt="" /></div>
          </div>
          <div
            class="insert_images"
            :class="url1 && !url2 ? `between_inputs` : `center_inputs`"
          >
            <input
              type="button"
              id="loadFileXml"
              class="add_btn"
              v-if="(url1 && url2) || (url1 && !url2)"
              value="Заменить фото"
              @click="replace_photo"
            />
            <input
              type="button"
              id="loadFileXml"
              class="add_btn"
              v-if="(!url1 && !url2) || (url1 && !url2)"
              value="Добавить фото"
              @click="add_photo"
            />
          </div>

          <input
            type="file"
            name="images"
            accept="capture=camera,image/*"
            class="choose_file"
            id="add_btn"
            multiple
            @input="onFileChange"
          />
          <p v-if="error">{{ error_text }}</p>
        </div>
        <input type="submit" value="Сохранить" class="save_btn" />
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AddContact",
  data() {
    return {
      today: new Date(),
      image_url: new URL("../assets/x.png", import.meta.url),
      placeholder_text: "Введите текст...",
      activity_profile: [],
      industry: [],
      liked_categories: [],
      is_robot: "",
      phone_number: [],
      url1: undefined,
      url2: undefined,
      error: false,
      replace: false,
      error_text: "",
      cities: [],
      photos: [],
    };
  },
  watch: {
    activity_profile(newVal) {
      console.log(newVal);
    },
  },
  beforeRouteEnter(to, from, next) {
    setTimeout(() => next(), 100);
  },
  mounted() {
    console.log("AddContact");
    let vm = this;
    setTimeout(() => vm.$store.dispatch("SET_LOAD_STATUS", false), 100);
  },
  computed: {
    current_event() {
      return this.$store.getters.GET_CURRENT_EVENT;
    },
  },
  methods: {
    checkboxed_values(type) {
      return this.$store.getters.GET_CHECKBOX_VALUES(type);
    },
    columns(values_list) {
      let columns = [];
      let mid = Math.ceil(values_list.length / 2);
      for (let col = 0; col < 2; col++) {
        columns.push(values_list.slice(col * mid, col * mid + mid));
      }
      return columns;
    },
    async submit_form(event) {
      if (!this.check_activity_profile()) {
        return;
      }

      const compressImage = async (file, { quality = 1, type = file.type }) => {
        const imageBitmap = await createImageBitmap(file);

        const canvas = document.createElement("canvas");
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(imageBitmap, 0, 0);

        const blob = await new Promise((resolve) =>
          canvas.toBlob(resolve, type, quality)
        );

        return new File([blob], file.name, {
          type: blob.type,
        });
      };

      let vm = this;
      this.$store
        .dispatch("SET_LOAD_VIEW", "save")
        .then(vm.$store.dispatch("SET_LOAD_STATUS", true));
      let form = event.target.elements;
      let form_data = new FormData();
      form_data.append("public_name", form.public_name.value);
      form_data.append("legal_name", form.legal_name.value);
      form_data.append("location", form.location.value);
      form_data.append("activity_profile", this.activity_profile.join(", "));
      form_data.append("industry", this.industry.join(", "));
      form_data.append("contact_full_name", form.contact_full_name.value);
      form_data.append("contact_job_title", form.contact_job_title.value);
      form_data.append("phone_number", this.phone_number.join(", "));
      form_data.append("email", form.email.value);
      form_data.append("liked_categories", this.liked_categories.join(", "));
      form_data.append("comment", form.comment.value);
      form_data.append("rating", this.is_robot);
      form_data.append("user_id", this.$store.getters.GET_USER.id);
      form_data.append("event_id", this.current_event.id);
      let current_date = new Date();
      form_data.append(
        "created_at",
        `${("0" + current_date.getDate()).slice(-2)}.${(
          "0" +
          (current_date.getMonth() + 1)
        ).slice(-2)}.${current_date.getFullYear()} ${(
          "0" + current_date.getHours()
        ).slice(-2)}:${("0" + current_date.getMinutes()).slice(-2)}:${(
          "0" + current_date.getSeconds()
        ).slice(-2)}`
      );
      if (this.photos[0]) {
        const file = this.photos[0];
        const dataTransfer = new DataTransfer();
        const compressedFile = await compressImage(file, {
          quality: 0.5,
          type: "image/jpeg",
        });
        dataTransfer.items.add(compressedFile);
        form_data.append("photo_1", dataTransfer.files[0]);
      }
      if (this.photos[1]) {
        const file = this.photos[1];
        const dataTransfer = new DataTransfer();
        const compressedFile = await compressImage(file, {
          quality: 0.5,
          type: "image/jpeg",
        });
        dataTransfer.items.add(compressedFile);
        form_data.append("photo_2", dataTransfer.files[0]);
      }
      axios({
        method: "post",
        url: `${process.env.VUE_APP_backurl}/add_contact`,
        data: form_data,
        headers: {
          token: this.$store.getters.GET_USER.token,
        },
      }).then((Responce) => {
        console.log(Responce.data);
        if (Responce.data == "Unathorized") {
          vm.$router.push("/login");
          return;
        }
        vm.$router.push("/list_contacts");
        return;
      });
    },
    onFileChange(event) {
      console.log(this.photos);
      if ((this.replace || !this.photos[0]) && event.target.files.length > 2) {
        this.error_text = "Добавить можно только 2 фото";
        this.error = true;
        return;
      } else if (
        this.photos[0] &&
        !this.replace &&
        event.target.files.length > 1
      ) {
        this.error_text = "Добавить можно только 1 фото";
        this.error = true;
        return;
      }
      if (this.replace) {
        this.url1 = undefined;
        this.url2 = undefined;
        this.photos = [];
      }
      this.error = false;
      for (let file_id = 0; file_id < event.target.files.length; file_id++) {
        // this.photos[file_id] = event.target.files[file_id];
        console.log("loop");
        if (!this.photos[0]) {
          this.photos[0] = event.target.files[file_id];
          this.url1 = URL.createObjectURL(event.target.files[file_id]);
        } else {
          this.photos[1] = event.target.files[file_id];
          this.url2 = URL.createObjectURL(event.target.files[file_id]);
        }
      }
      console.log(this.photos);
    },
    add_photo() {
      this.replace = false;
      document.getElementById("add_btn").click();
    },
    replace_photo() {
      this.replace = true;
      document.getElementById("add_btn").click();
    },
    email_check(event) {
      if (
        !event.target.value.match(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w+)+$/)
      ) {
        event.target.setCustomValidity(
          "Адрес почты может содержать только латинские буквы, . - _ @ и адрес домена"
        );
        this.image_url = new URL("../assets/x.png", import.meta.url);
        event.target.reportValidity();
        return;
      } else {
        this.image_url = new URL("../assets/loading.gif", import.meta.url);
        let form_data = new FormData();
        form_data.append("email", event.target.value);
        axios({
          method: "post",
          url: `${process.env.VUE_APP_backurl}/check_email`,
          data: form_data,
          // headers: {
          //   token: this.$store.getters.GET_USER.token,
          // },
        }).then((Responce) => {
          console.log(Responce.data);
          if (Responce.data == "Exists") {
            this.image_url = new URL("../assets/x.png", import.meta.url);
            event.target.setCustomValidity("Эта почта уже была добавлена");
            event.target.reportValidity();
            return;
          } else {
            this.image_url = new URL(
              "../assets/check_mark.png",
              import.meta.url
            );
            event.target.setCustomValidity("");
            return;
          }
        });
      }
    },
    request_list(event) {
      let vm = this;
      let form_data = new FormData();
      form_data.append("word", event.target.value);
      axios({
        method: "post",
        url: `${process.env.VUE_APP_backurl}/get_cities`,
        data: form_data,
        // headers: {
        //   token: this.$store.getters.GET_USER.token,
        // },
      }).then((Responce) => {
        vm.cities = Responce.data;
      });
    },
    check_activity_profile() {
      if (this.activity_profile.length == 0) {
        this.error_text = "Не заполнено поле Профиля деятельности";
        this.error = true;
        return false;
      } else {
        this.error = false;
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-enter-active,
.loader-leave-active {
  transition: opacity 0.1s ease;
}

.loader-enter-from,
.loader-leave-to {
  opacity: 0;
}
.navbarContainer {
  background-color: #eeeeee;
  width: 100%;
  position: sticky;
  top: 0;
  padding: 30px 0;
  z-index: 10;
}
.navcont {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close_btn {
  background: none;
  border: none;
  font-size: 2.5rem;
}
.close_btn:active {
  opacity: 0.7;
}
.card {
  padding: 20px;
}
.linp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 700;
}
label input {
  width: 100%;
  border: 0;
  border-radius: 20px;
  line-height: 2.3rem;
  padding-left: 11px;
  font-weight: 700;
  font-size: 1.1rem;
}
::placeholder {
  color: #838383;
}
.checkb {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-top: 5%;
  width: 100%;
  word-break: break-word;
}
.radiob {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-top: 5%;
}
.radio_btn {
  margin-right: 10px;
}
.text_inp {
  margin-top: 3px;
}
.s_text_inp {
  margin-top: 10px;
}
.s_text_inp:disabled {
  background-color: white;
  opacity: 0.6;
}
.inputs {
  width: 100%;
  display: flex;
  font-size: 1rem;
  margin-bottom: 10px;
}
.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}
.profile,
.otrasl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.robots {
  display: flex;
  justify-content: space-between;
}
.robots input {
  transform: scale(1.5);
}
.insert_images {
  display: flex;
}
.center_inputs {
  justify-content: center;
}
.between_inputs {
  justify-content: space-between;
}
.photos {
  margin: 30px 0 20px 0;
  display: flex;
  justify-content: space-between;
}
.photo {
  background-color: #acacac;
  border-radius: 20px;
  width: 47%;
  height: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.photo img {
  display: inline-block;
  width: 100%;
}
.add_btn {
  height: 30px;
  border-radius: 15px;
  padding: 0 16px;
  background-color: #292a4d;
  color: white;
  font-weight: 700;
  border: none;
}
.add_btn:active {
  opacity: 0.7;
}
.choose_file {
  display: none;
}
.save_btn {
  background: #ff739d;
  background: linear-gradient(90deg, #ff739d 0, #ca73ff 50%, #7388ff 100%);
  border: none;
  padding: 17px 60px;
  border-radius: 20px;
  color: white;
  font-size: 1.3rem;
  margin-top: 50px;
}
.save_btn:active {
  opacity: 0.7;
}
.save_btn:disabled {
  filter: grayscale(1);
}
.line {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin: 20px 0;
}
.input_for_validate {
  height: 0;
  width: 0;
  opacity: 0;
}
.email_input {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: white;
  border-radius: 20px;
}
.check_email_icon {
  width: 30px;
  height: 30px;
  padding: 7px;
}

.check_btn {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
}
.checkmark {
  text-align: left;
  line-height: 1rem;
}
.check_icon {
  content: "";
  display: inline-block;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 10px;
  border-radius: 50%;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}
.checkb input:checked ~ .check_icon {
  background-image: url("../assets/check_btn/checked.png");
}
</style>
