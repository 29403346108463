<template>
  <div class="edit_field_comp">
    <div class="navbarContainer">
      <div class="navcont">
        <h3>
          {{ current_event.name }} {{ current_event.current_day.getDate() }}.{{
            current_event.current_day.getMonth() + 1
          }}.{{ current_event.current_day.getFullYear() }}
        </h3>
        <button class="close_btn" @click="$emit(`close`, true)">&times;</button>
      </div>
    </div>
    <form @submit.prevent="submit_form" class="login_form">
      <div class="card">
        <div class="general_inf">
          <div v-if="field_type == `text`">
            <label class="linp"
              >{{ name_list[field_name]
              }}<input
                type="text"
                class="text_inp"
                placeholder="Введите текст..."
                name="field"
                v-model="edit_value"
                required
            /></label>
          </div>
          <div v-if="field_type == `city`">
            <label class="linp"
              >{{ name_list[field_name]
              }}<input
                list="cities"
                type="text"
                class="text_inp"
                placeholder="Введите текст..."
                name="field"
                v-model="edit_value"
                @input="request_list"
                required
            /></label>
            <datalist id="cities">
              <option v-for="city in cities" :key="city.id">{{ city }}</option>
            </datalist>
          </div>
          <div class="otrasl" v-if="field_type == `checkbox`">
            <label class="linp">{{ name_list[field_name] }}</label>
            <div class="inputs">
              <div class="f_column">
                <label
                  class="checkb"
                  v-for="value in current_checkbox_values"
                  :key="value.id"
                  ><input
                    type="checkbox"
                    class="check_btn"
                    v-model="edit_value"
                    :value="value"
                  />
                  <div class="check_icon"></div>
                  <span class="checkmark">{{ value }}</span></label
                >
              </div>
            </div>
          </div>
          <div class="" v-if="field_type == `tel`">
            <label class="linp"
              >{{ name_list[field_name]
              }}<input
                type="tel"
                class="text_inp"
                placeholder="Введите текст..."
                name="phone_number1"
                inputmode="tel"
                v-model="edit_value[0]"
                required /><input
                type="tel"
                class="s_text_inp"
                placeholder="Введите текст..."
                name="phone_number2"
                inputmode="tel"
                v-model="edit_value[1]"
                :disabled="!edit_value[0] ? true : false"
            /></label>
          </div>
        </div>
        <div class="contact_inf" v-if="field_type == `email`">
          <label class="linp"
            >{{ name_list[field_name]
            }}<input
              type="email"
              class="text_inp"
              placeholder="Введите текст..."
              name="email"
              inputmode="email"
              v-model="edit_value"
              required
          /></label>
        </div>
        <div class="" v-if="field_type == `rating`">
          <label class="linp">{{ name_list[field_name] }}</label>
          <div class="robots">
            <label
              class="radiob"
              v-for="robot in [1, 2, 3, 4, 5]"
              :key="robot.id"
              ><input
                type="radio"
                class="radio_btn"
                v-model="edit_value"
                :value="robot"
              /><span class="radiomark"></span>{{ robot }}</label
            >
          </div>
        </div>
        <input
          type="submit"
          value="Сохранить"
          class="save_btn"
          :disabled="check_edit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "EditField",
  props: {
    field_name: String,
    field_type: String,
    field_value: [String, Array, Number],
  },
  data() {
    return {
      activity_profile: [],
      edit_value: JSON.parse(JSON.stringify(this.field_value)),
      liked_categories: [],
      phone_number: [],
      photo_error: false,
      photo_error_text: "",
      name_list: {
        public_name: "Публичное наименование",
        legal_name: "Юридическое лицо",
        location: "Город, регион",
        activity_profile: "Профиль деятельности",
        industry: "Отрасль",
        contact_full_name: "ФИО",
        contact_job_title: "Должность",
        phone_number: "Телефон",
        email: "E-mail",
        liked_categories: "Интересные категории",
        comment: "Комментарий",
        rating: "Оценка",
      },
      current_checkbox_values: [],
      activity_profile_values: [
        "Розница",
        "ОПТ",
        "Сеть",
        "Интернет",
        "Маркетплейс",
        "Прочее",
      ],
      industry_values: [
        "Детские товары",
        "Канцелярия",
        "Книжный",
        "Продовольствия",
        "Drogeire",
        "Прочее",
      ],
      liked_categories_values: [
        "Все товары",
        "Косметика",
        "Куклы",
        "Украшения",
        "Слаймы",
        "Песок",
        "Опыты",
        "Легкий пластилин",
        "Тесто",
      ],
    };
  },
  created() {
    if (this.field_name == "activity_profile") {
      this.current_checkbox_values = this.activity_profile_values;
    } else if (this.field_name == "industry") {
      this.current_checkbox_values = this.industry_values;
    } else if (this.field_name == "liked_categories") {
      this.current_checkbox_values = this.liked_categories_values;
    }
  },
  mounted() {
    console.log("EditField");
  },
  computed: {
    check_edit() {
      if (Array.isArray(this.edit_value)) {
        if (
          this.field_value.every((elem) => this.edit_value.includes(elem)) &&
          this.edit_value.every((elem) => this.field_value.includes(elem))
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.field_value == this.edit_value) {
        return true;
      } else {
        return false;
      }
    },
    current_event() {
      return this.$store.getters.GET_CURRENT_EVENT;
    },
  },
  methods: {
    submit_form() {
      let result_value = "";
      if (Array.isArray(this.edit_value)) {
        result_value = this.edit_value.join(", ");
      } else {
        result_value = this.edit_value;
      }
      this.$store.dispatch("SET_LOAD_STATUS", true);
      let vm = this;
      let form_data = new FormData();
      form_data.append("field_name", this.field_name);
      form_data.append("value", result_value);
      form_data.append(
        "contact_id",
        this.$store.getters.GET_CURRENT_CONTACT.id
      );
      axios({
        method: "post",
        url: `${process.env.VUE_APP_backurl}/edit_contact`,
        data: form_data,
        headers: {
          token: this.$store.getters.GET_USER.token,
        },
      }).then((Responce) => {
        console.log(Responce.data);
        if (Responce.data == "Unathorized") {
          vm.$router.push("/login");
          return;
        }
        vm.$store
          .dispatch(
            "SET_CURRENT_CONTACT",
            this.$store.getters.GET_CURRENT_CONTACT.id
          )
          .then(setTimeout(() => vm.$emit(`close`, true), 100));
        console.log("SUcceSS");
        return;
      });
    },
    request_list(event) {
      console.log(event.target.value);
      let vm = this;
      let form_data = new FormData();
      form_data.append("word", event.target.value);
      axios({
        method: "post",
        url: `${process.env.VUE_APP_backurl}/get_cities`,
        data: form_data,
        // headers: {
        //   token: this.$store.getters.GET_USER.token,
        // },
      }).then((Responce) => {
        console.log(Responce.data);
        vm.cities = Responce.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-enter-active,
.loader-leave-active {
  transition: opacity 0.1s ease;
}

.loader-enter-from,
.loader-leave-to {
  opacity: 0;
}
.navbarContainer {
  background-color: #eeeeee;
  width: 100%;
  position: sticky;
  top: 0;
  padding: 30px 0;
}
.navcont {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close_btn {
  background: none;
  border: none;
  font-size: 2.5rem;
}
.close_btn:active {
  opacity: 0.7;
}
.card {
  padding: 20px;
}
.linp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 700;
}
label input {
  width: 100%;
  border: 0;
  border-radius: 20px;
  line-height: 2.3rem;
  padding-left: 11px;
  font-weight: 700;
  font-size: 1.1rem;
}
::placeholder {
  color: #838383;
}
.checkb {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-top: 5%;
  width: 100%;
  word-break: break-word;
}
.radiob {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-top: 5%;
}
.radio_btn {
  margin-right: 10px;
}
.text_inp {
  margin-top: 3px;
}
.s_text_inp {
  margin-top: 10px;
}
.inputs {
  width: 100%;
  display: flex;
  font-size: 1rem;
}
.f_column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.profile,
.otrasl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.save_btn {
  background: #ff739d;
  background: linear-gradient(90deg, #ff739d 0, #ca73ff 50%, #7388ff 100%);
  border: none;
  padding: 17px 60px;
  border-radius: 20px;
  color: white;
  font-size: 1.3rem;
  margin-top: 50px;
}
.save_btn:active {
  opacity: 0.7;
}
.save_btn:disabled {
  filter: grayscale(1);
}
.save_active {
  display: none;
}
.edit_field_comp {
  background-color: #eeeeee;
  height: 100svh;
  position: fixed;
  z-index: 400;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.robots {
  display: flex;
  justify-content: space-between;
}
.robots input {
  transform: scale(1.5);
}

.check_btn {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
}
.checkmark {
  display: inline-flex;
  align-items: center;
  line-height: 1rem;
}
.check_icon {
  content: "";
  display: inline-block;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 10px;
  border-radius: 50%;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}
.checkb input:checked ~ .check_icon {
  background-image: url("../assets/check_btn/checked.png");
}
</style>
