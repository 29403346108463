<template>
  <div>
    <div class="profile_component">
      <div class="navbarContainer">
        <button class="logout_btn" @click="logout">
          <img src="../assets/logout_btn.png" alt="" />
        </button>
      </div>
      <div class="profile_container">
        <div class="user_data">
          <img
            :src="
              this.$store.getters.GET_USER.photo_url
                ? this.$store.getters.GET_USER.photo_url
                : require('../assets/profile_photo_big.png')
            "
            alt=""
          />
          <h4 class="full_name_text">{{ user.full_name }}</h4>
          <p class="job_title_text">{{ user.job_title }}</p>
        </div>
        <div class="user_achievements">
          <!-- <div class="number_contacts">
            <h2>{{ number_contacts }}</h2>
            <h4>Контакт{{ pluralizeRus(number_contacts, ["", "а", "ов"]) }}</h4>
          </div> -->
          <div class="number_contacts">
            <h2>Всего контактов - {{ number_contacts }}</h2>
          </div>
        </div>
        <div class="event" v-for="event in detailed_count" :key="event.id">
          <div class="event_name">
            <h2>{{ event.name }}</h2>
            <h2>{{ event.count }}</h2>
          </div>
          <div class="event_days">
            <div class="event_day" v-for="day in event.days" :key="day.id">
              <h3>{{ day.date }}</h3>
              <h3>{{ day.count }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="event_container">
        <button
          class="change_event_btn"
          @click="
            this.$store
              .dispatch(`SET_LOAD_STATUS`, true)
              .then($router.push(`/list_events`))
          "
        >
          Сменить мероприятие
        </button>
      </div>
    </div>
    <BottomMenu :bottom_menu_page="3" />
  </div>
</template>

<script>
import BottomMenu from "./BottomMenu.vue";
import axios from "axios";

export default {
  name: "ProfileView",
  components: {
    BottomMenu,
  },
  data() {
    return {
      number_contacts: 0,
      detailed_count: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    setTimeout(() => next(), 100);
  },
  created() {
    let vm = this;
    this.$store.dispatch("SET_USER_INFO", localStorage.getItem("token"));
    axios
      .get(
        `${
          process.env.VUE_APP_backurl
        }/get_number_user_contacts/${localStorage.getItem("user_id")}`
      )
      .then(function (Responce) {
        vm.number_contacts = Responce.data;
      });
    axios
      .get(
        `${
          process.env.VUE_APP_backurl
        }/get_statistic_by_events/${localStorage.getItem("user_id")}`
      )
      .then(function (Responce) {
        vm.detailed_count = Responce.data;
      });
  },
  mounted() {
    console.log("ViewProfile");
    let vm = this;
    if (this.$store.getters.GET_LOAD_VIEW == "hello") {
      setTimeout(
        () =>
          vm.$store
            .dispatch("SET_LOAD_STATUS", false)
            .then(vm.$store.dispatch("SET_LOAD_VIEW", "default")),
        2000
      );
    } else {
      setTimeout(() => vm.$store.dispatch("SET_LOAD_STATUS", false), 100);
    }
  },
  computed: {
    user() {
      return this.$store.getters.GET_USER;
    },
  },
  methods: {
    pluralizeRus(n, forms) {
      return n % 10 == 1 && n % 100 != 11
        ? forms[0]
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
        ? forms[1]
        : forms[2];
    },
    logout() {
      let vm = this;
      this.$store
        .dispatch("SET_LOAD_STATUS", true)
        .then(vm.$store.dispatch(`LOGOFF`));
    },
  },
};
</script>

<style lang="scss" scoped>
.profile_component {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  // padding-top: 30%;
}
.profile_container {
  // margin-top: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.save_btn {
  background: #ff739d;
  background: linear-gradient(90deg, #ff739d 0, #ca73ff 50%, #7388ff 100%);
  border: none;
  padding: 17px 60px;
  border-radius: 20px;
  color: white;
  font-size: 1.3rem;
  margin-top: 50px;
  filter: grayscale(1);
}
.change_event_btn {
  background: #ff739d;
  background: linear-gradient(90deg, #ff739d 0, #ca73ff 50%, #7388ff 100%);
  border: none;
  width: 90%;
  padding: 17px 5px;
  border-radius: 20px;
  color: white;
  font-size: 1.3rem;
  margin-top: 50px;
  margin-bottom: 25%;
}
.logoff_btn {
  background-color: red;
  border: none;
  padding: 17px 60px;
  border-radius: 20px;
  color: white;
  font-size: 1.3rem;
  margin-top: 50px;
}
.save_btn:active {
  opacity: 0.7;
}
.user_achievements {
  display: flex;
  justify-content: center;
}
.number_contacts {
  background-color: white;
  border-radius: 15px;
  padding: 5px 10px;
  margin: 5% 0;
}
.user_data img {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 50%;
}
.full_name_text {
  margin-top: 7%;
  font-size: 1.2rem;
}
.job_title_text {
  margin-top: 2%;
}
.logout_btn {
  background: none;
  border: none;
  padding: 5px;
  border-radius: 5px;
}
.logout_btn:active img {
  filter: invert(14%) sepia(95%) saturate(7431%) hue-rotate(5deg)
    brightness(102%) contrast(115%);
}
.logout_btn img {
  width: 30px;
}
.navbarContainer {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.event {
  margin: 0 5%;
}
.event_name {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000000;
}
.event_day {
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
}
</style>
