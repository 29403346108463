<template>
  <div class="list_card_comp">
    <div class="photo_container">
      <img :src="photo" alt="" />
    </div>
    <button class="close_btn" @click="$emit(`close`, true)">&times;</button>
  </div>
</template>

<script>
export default {
  name: "PhotoModal",
  props: {
    photo: String,
  },
};
</script>

<style lang="scss" scoped>
.list_card_comp {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 100svh;
  width: 100%;
  z-index: 500;
  position: fixed;
  top: 0;
  bottom: 0;
}
.photo_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.close_btn {
  position: absolute;
  bottom: 7%;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 50px;
  line-height: 50px;
}
.close_btn:active {
  opacity: 0.7;
}
.photo_container img {
  width: 100%;
}
</style>
