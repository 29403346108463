<template>
  <div>
    <div v-if="!edit_field">
      <div class="navbarContainer">
        <div class="navcont">
          <h3>
            {{ current_event.name }}
            {{ ("0" + add_date.getDate()).slice(-2) }}.{{
              ("0" + (add_date.getMonth() + 1)).slice(-2)
            }}.{{ add_date.getFullYear() }}
          </h3>
          <button class="close_btn" @click="close_contact">&times;</button>
        </div>
      </div>
      <div class="card">
        <div class="general_inf">
          <div class="card_item">
            <div class="company_contact">
              <p class="company quick_inf_type">Публичное наименование</p>
              <h3 class="contact_value company_name">
                {{ current_contact.public_name }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="
                    edit(`text`, `public_name`, current_contact.public_name)
                  "
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="card_item">
            <div class="company_contact">
              <p class="company quick_inf_type">Юридическое лицо</p>
              <h3 class="contact_value company_name">
                {{ current_contact.legal_name }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="
                    edit(`text`, `legal_name`, current_contact.legal_name)
                  "
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="card_item">
            <div class="company_contact">
              <p class="company quick_inf_type">Город, регион</p>
              <h3 class="contact_value company_name">
                {{ current_contact.location }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="edit(`city`, `location`, current_contact.location)"
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="card_item">
            <div class="company_contact">
              <p class="company quick_inf_type">Профиль деятельности</p>
              <h3 class="contact_value company_name">
                {{ current_contact.activity_profile }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="
                    edit(
                      `checkbox`,
                      `activity_profile`,
                      current_contact.activity_profile.split(`, `)
                    )
                  "
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="card_item">
            <div class="company_contact">
              <p class="company quick_inf_type">Отрасль</p>
              <h3 class="contact_value company_name">
                {{ current_contact.industry }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="
                    edit(
                      `checkbox`,
                      `industry`,
                      current_contact.industry
                        ? current_contact.industry.split(`, `)
                        : []
                    )
                  "
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="line"></div>
          <div class="card_item">
            <div class="company_contact">
              <h3 class="contact_value company_name">
                {{ current_contact.contact_full_name }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="
                    edit(
                      `text`,
                      `contact_full_name`,
                      current_contact.contact_full_name
                    )
                  "
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="card_item">
            <div class="company_contact">
              <p class="company quick_inf_type">Должность</p>
              <h3 class="contact_value company_name">
                {{ current_contact.contact_job_title }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="
                    edit(
                      `text`,
                      `contact_job_title`,
                      current_contact.contact_job_title
                    )
                  "
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="card_item">
            <div class="company_contact">
              <p class="company quick_inf_type">Телефон</p>
              <h3 class="contact_value company_name">
                {{ current_contact.phone_number1
                }}<br v-if="current_contact.phone_number2" />{{
                  current_contact.phone_number2
                }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="
                    edit(`tel`, `phone_number`, [
                      current_contact.phone_number1,
                      current_contact.phone_number2,
                    ])
                  "
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="card_item">
            <div class="company_contact">
              <p class="company quick_inf_type">E-mail</p>
              <h3 class="contact_value company_name">
                {{ current_contact.email }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="edit(`email`, `email`, current_contact.email)"
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="line"></div>
          <div class="card_item">
            <div class="company_contact">
              <p class="company quick_inf_type">Интересные категории</p>
              <h3 class="contact_value company_name">
                {{ current_contact.liked_categories }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="
                    edit(
                      `checkbox`,
                      `liked_categories`,
                      current_contact.liked_categories
                        ? current_contact.liked_categories.split(`, `)
                        : []
                    )
                  "
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="card_item">
            <div class="company_contact">
              <p class="company quick_inf_type">Комментарий</p>
              <h3 class="contact_value company_name">
                {{ current_contact.comment }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="edit(`text`, `comment`, current_contact.comment)"
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="card_item">
            <div class="company_contact">
              <p class="company quick_inf_type">Оценка</p>
              <h3 class="contact_value company_name">
                {{ current_contact.rating }}
                <button
                  v-if="allow_edit"
                  class="edit_field_btn"
                  @click="edit(`rating`, `rating`, current_contact.rating)"
                >
                  <img src="../assets/edit_field_btn.png" alt="" />
                </button>
              </h3>
            </div>
          </div>
          <div class="photo_visitka">
            <div class="photos">
              <div
                class="photo"
                @click="show_photo_clck(current_contact.photo_url1)"
              >
                <img :src="current_contact.photo_url1" alt="" />
              </div>
              <div
                class="photo"
                @click="show_photo_clck(current_contact.photo_url2)"
              >
                <img :src="current_contact.photo_url2" alt="" />
              </div>
            </div>
          </div>
          <p>
            Дата переговоров: {{ ("0" + add_date.getDate()).slice(-2) }}.{{
              ("0" + (add_date.getMonth() + 1)).slice(-2)
            }}.{{ add_date.getFullYear() }}
            {{ ("0" + add_date.getHours()).slice(-2) }}:{{
              ("0" + add_date.getMinutes()).slice(-2)
            }}:{{ ("0" + add_date.getSeconds()).slice(-2) }}
          </p>
          <div class="line"></div>
          <div class="athor_info">
            <p>Сотрудник заполнивший анкету</p>
            <img
              :src="
                current_contact.athor_photo_url
                  ? current_contact.athor_photo_url
                  : require('../assets/profile_photo.png')
              "
              alt=""
            />
            <h4>{{ current_contact.athor_fullname }}</h4>
          </div>
        </div>
      </div>
    </div>
    <PhotoModal
      :photo="current_image"
      v-if="show_photo"
      @close="show_photo = false"
    />
    <EditField
      :field_name="edit_field_name"
      :field_type="edit_field_type"
      :field_value="edit_field_value"
      v-if="edit_field"
      @close="close_edit"
    />
  </div>
</template>

<script>
import PhotoModal from "./PhotoModal.vue";
import EditField from "./EditField.vue";
export default {
  name: "AddContact",
  components: {
    PhotoModal,
    EditField,
  },
  data() {
    return {
      show_photo: false,
      current_image: "",
      edit_field_type: "",
      edit_field_name: "",
      edit_field_value: "",
      edit_field: false,
      allow_edit: false,
    };
  },
  computed: {
    current_event() {
      return this.$store.getters.GET_CURRENT_EVENT;
    },
    current_contact() {
      return this.$store.getters.GET_CURRENT_CONTACT;
    },
    add_date() {
      let date = this.$store.getters.GET_CURRENT_CONTACT.created_at;
      if (date == undefined) {
        return new Date("2000-01-01");
      } else {
        return date;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    setTimeout(() => next(), 100);
  },
  beforeMount() {
    if (
      this.$store.getters.GET_CURRENT_CONTACT.athor_id ==
      this.$store.getters.GET_USER.id
    ) {
      this.allow_edit = true;
    }
  },
  mounted() {
    console.log("ViewContact");
    let vm = this;
    setTimeout(() => vm.$store.dispatch("SET_LOAD_STATUS", false), 100);
  },
  methods: {
    show_photo_clck(image) {
      if (image == null) {
        return;
      }
      this.current_image = image;
      this.show_photo = true;
    },
    edit(type, name, value) {
      console.log(type);
      console.log(name);
      console.log(value);
      this.edit_field_type = type;
      this.edit_field_name = name;
      this.edit_field_value = value;
      this.edit_field = true;
    },
    close_edit() {
      let vm = this;
      this.edit_field = false;
      setTimeout(() => vm.$store.dispatch(`SET_LOAD_STATUS`, false), 100);
    },
    close_contact() {
      let vm = this;
      this.$store.dispatch(`SET_LOAD_STATUS`, true);
      setTimeout(() => vm.$router.push(`/list_contacts`), 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbarContainer {
  background-color: #eeeeee;
  width: 100%;
  position: sticky;
  top: 0;
  padding: 30px 0;
}
.navcont {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close_btn {
  background: none;
  border: none;
  font-size: 2.5rem;
}
.card {
  padding: 20px;
}
label input {
  width: 100%;
  border: 0;
  border-radius: 20px;
  line-height: 2.3rem;
  padding-left: 11px;
  font-weight: 700;
  font-size: 1.1rem;
}
::placeholder {
  color: #838383;
}
.photos {
  margin: 30px 0 20px 0;
  display: flex;
  justify-content: space-between;
}
.photo {
  background-color: #acacac;
  border-radius: 20px;
  width: 47%;
  height: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.photo img {
  display: inline-block;
  width: 100%;
}
.add_btn {
  height: 30px;
  border-radius: 15px;
  padding: 0 16px;
  background-color: #292a4d;
  color: white;
  font-weight: 700;
  border: none;
}
.choose_file {
  display: none;
}
.card_item {
  background-color: white;
  display: flex;
  border-radius: 20px;
  padding: 5px 15px;
  justify-content: space-between;
  margin-top: 10px;
}
.company_contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.quick_inf_type {
  color: grey;
}
.company_name {
  width: 100%;
  text-align: left;
}
.edit_field_btn {
  background: none;
  border: none;
  vertical-align: text-bottom;
}
.edit_field_btn:active {
  opacity: 0.7;
}
.line {
  height: 1px;
  width: 100%;
  background-color: #d9d9d9;
  margin: 20px 0;
}
.add_btn:active {
  opacity: 0.7;
}
.athor_info img {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
}
</style>
