<template>
  <div class="list_card_comp">
    <div class="logo">
      <img src="../assets/logo.png" alt="asd" />
    </div>
    <h1 class="login_header">Вход</h1>
    <form @submit.prevent="submit_form" class="login_form">
      <input
        class="form_input"
        type="text"
        placeholder="Имя пользователя"
        name="username"
        required
      />
      <input
        class="form_input"
        type="password"
        placeholder="Пароль"
        inputmode="numeric"
        maxlength="4"
        name="password"
        required
      />
      <p v-if="logon_error" class="logon_error">
        Неправильный логин или пароль
      </p>
      <div class="login_btn">
        <input type="submit" value="Войти" />
      </div>
    </form>
    <p class="bottom_text">
      Компания «Волшебный мир» © {{ new Date().getFullYear() }}<br />сайта
      <a href="https://volshebniymir.ru/">volshebniymir.ru</a>
    </p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LoginWin",
  data() {
    return {
      logon_error: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    setTimeout(() => next(), 100);
  },
  mounted() {
    let vm = this;
    setTimeout(() => vm.$store.dispatch("SET_LOAD_STATUS", false), 100);
  },
  methods: {
    submit_form(e) {
      let vm = this;
      let form_data = new FormData();
      form_data.append(
        "username",
        e.target.elements.username.value.toLowerCase()
      );
      form_data.append("password", e.target.elements.password.value);
      axios({
        method: "post",
        url: `${process.env.VUE_APP_backurl}/login`,
        data: form_data,
      }).then(function (Responce) {
        if (Responce.data.logon == true) {
          vm.$store.dispatch("SUCCESS_LOGON", Responce.data);
          vm.$store
            .dispatch("SET_LOAD_STATUS", true)
            .then(
              vm.$store
                .dispatch("SET_LOAD_VIEW", "hello")
                .then(setTimeout(() => vm.$router.push("/list_events"), 100))
            );
        } else {
          vm.logon_error = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list_card_comp {
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100svh;
  width: 100%;
  background-position: 25% 25%;
  background: radial-gradient(
    circle at 50% 20%,
    #b3c5ff 1%,
    #4860c0 30%,
    #161629
  );
}
.logo {
  margin-top: 25%;
}
.logo img {
  width: 50%;
}
.login_form {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.login_btn input {
  background: #ff739d;
  background: linear-gradient(90deg, #ff739d 0, #ca73ff 50%, #7388ff 100%);
  border: none;
  padding: 17px 60px;
  border-radius: 20px;
  color: white;
  font-size: 1.5rem;
  margin-top: 50px;
}
.form_input {
  border: none;
  border-radius: 20px;
  padding: 20px 10px;
  margin-top: 20px;
}
.login_header {
  color: white;
  font-weight: 400;
}
.bottom_text {
  color: #4860c0;
  margin-bottom: 20px;
}
.bottom_text a {
  text-decoration: none;
}
.logon_error {
  color: red;
}
</style>
